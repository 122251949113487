import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { LandingPage } from "./pages/LandingPage";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const firebaseConfig = {
  apiKey: "AIzaSyBBNQ4tO_k22KUDSzBmQSQmEt9RnzS_rZ4",
  authDomain: "what-about-the-deacon.firebaseapp.com",
  projectId: "what-about-the-deacon",
  storageBucket: "what-about-the-deacon.appspot.com",
  messagingSenderId: "1024507985675",
  appId: "1:1024507985675:web:a173ff934f485937732c0e",
  measurementId: "G-K4H31QX1DB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

function App() {
  return (
    <div style={{ backgroundColor: "#222" }}>
      <LandingPage storage={storage} DB={db} />
    </div>
  );
}

export default App;
