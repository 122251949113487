import React, { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  Typography,
  Button,
  IconButton,
  Box,
  AppBar,
  Toolbar,
  Card,
  CardMedia,
  Modal,
  TextField,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { VideoList } from "../components/VideoList";
import ReactPlayer from "react-player";
import moment from "moment";
// import pattern from "../assets/protest-pattern.png";
import signaturesBg from "../assets/signatures.png";
import screening from "../assets/screening.jpg";
import { LinearGradient } from "react-text-gradients";
import {
  PlayCircleOutline,
  NoteAltOutlined,
  Theaters,
  PlayCircle,
  Close,
  Videocam,
  Upload,
  PedalBikeOutlined,
} from "@mui/icons-material";
import { ScreeningForm } from "../components/ScreeningForm";
// import CountUp from "react-countup";
import Reaction1 from "../assets/reactions/1.mp4";
import Reaction2 from "../assets/reactions/2.mp4";
import Reaction3 from "../assets/reactions/3.mp4";
import Reaction4 from "../assets/reactions/4.mp4";
import Reaction5 from "../assets/reactions/5.mp4";
import Reaction6 from "../assets/reactions/6.mp4";
import Reaction7 from "../assets/reactions/7.mp4";
import Reaction8 from "../assets/reactions/8.mp4";

export const LandingPage = ({ storage, DB }) => {
  const targetDate = "2023-08-10";
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [signatures, setSignatures] = useState(2829);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentReactionVideo, setCurrentReactionVideo] = useState(null);
  const [documentaryVisible, setDocumentaryVisible] = useState(false);
  const [uploadFormVisible, setUploadFormVisible] = useState(false);
  const [hostingFormVisible, setHostingFormVisible] = useState(false);

  const reactionVideos = [
    { video: Reaction6, img: 6 },
    { video: Reaction8, img: 8 },
    { video: Reaction2, img: 2 },
    { video: Reaction1, img: 1 },
    { video: Reaction3, img: 3 },
    { video: Reaction4, img: 4 },
    { video: Reaction5, img: 5 },
    { video: Reaction7, img: 7 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  function calculateTimeLeft() {
    const now = moment();
    const target = moment(targetDate);

    const diff = moment.duration(now.diff(target));

    // Calculating remaining days, hours, minutes, and seconds
    return {
      years: diff.years(),
      months: diff.months(),
      days: diff.days(),
      hours: diff.hours(),
      minutes: diff.minutes(),
      seconds: diff.seconds(),
    };
  }

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    file: null,
  });

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.file) {
      alert("Please upload a file first.");
      return;
    }

    const storageRef = ref(storage, `reactions/${formData.file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, formData.file);

    setUploading(true);

    // Track upload progress
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading file: ", error);
        setUploading(false);
      },
      () => {
        // When upload completes, get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          try {
            await addDoc(collection(DB, "reactions"), {
              fullName: formData.fullName,
              email: formData.email,
              downloadUrl: downloadURL,
              timestamp: new Date(),
            });
            alert("File uploaded and data saved successfully!");
          } catch (error) {
            console.error("Error saving to Firestore: ", error);
          }
          setUploading(false);
          setUploadFormVisible(false);
          setFormData({ fullName: "", email: "", file: null });
          alert("File uploaded successfully!");
        });
      }
    );
  };

  return (
    <>
      <div style={{ backgroundColor: "#000" }}>
        {/* Header */}
        <AppBar position="fixed" color="transparent">
          <Toolbar
            style={{
              justifyContent: "center",
              background: "rgba(31, 117, 254,0.6)",
              minHeight: "50px",
            }}
          >
            {["years", "months", "days", "hours", "minutes", "seconds"].map(
              (interval) => {
                return (
                  <>
                    <Typography
                      style={{
                        fontSize: isMobile ? "16px" : "32px",
                        fontWeight: "900",
                        marginRight: isMobile ? "2px" : "4px",
                      }}
                      variant="h2"
                      color="#f5f5f5"
                    >
                      {timeLeft[interval]}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: isMobile ? "12px" : "30px",
                        fontWeight: "100",
                        fontFamily: "sans-serif",
                        color: "#f5f5f5",
                        marginRight: "8px",
                      }}
                    >
                      {timeLeft[interval] === 1
                        ? interval.slice(0, -1)
                        : interval}
                    </Typography>
                  </>
                );
              }
            )}
          </Toolbar>
          <div
            style={{
              textAlign: "center",
              background: "rgba(31, 117, 254,0.6)",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: "0 0 16px",
            }}
          >
            <div
              style={{
                background: "#f5f5f5",
                borderRadius: "50px",
                width: "200px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontWeight: "900",
                  fontFamily: "sans-serif",
                  color: "rgba(31, 117, 254,0.6)",
                }}
              >
                WITHOUT JUSTICE
              </Typography>
            </div>
          </div>
        </AppBar>

        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: `85vh`,
            alignItems: "center",
          }}
        >
          {!isMobile && (
            <ReactPlayer
              auto
              playing
              loop={true}
              muted
              url={[
                { src: require("../assets/trailer.mp4"), type: "video/mp4" },
              ]}
              width={"100%"}
              height="auto"
              style={{
                width: "100%",
                opacity: 0.4,
              }}
            />
          )}

          <Grid
            container
            spacing={2}
            style={{
              position: "absolute",
              top: "30%",
              justifyContent: "center",
              display: "flex",
              width: "1440px",
              maxWidth: "100%",
              padding: "0 40px",
            }}
          >
            <Grid size={{ xs: 12, md: 5 }}>
              <Typography
                variant="h1"
                style={{
                  fontSize: isMobile ? "60px" : "80px",
                  fontWeight: "900",
                  fontFamily: "inherit",
                  lineHeight: 0.9,
                  color: "palegoldenerod",
                  marginBottom: "10px",
                }}
              >
                <LinearGradient
                  gradient={["to bottom", "#1f75fe, skyblue, silver, #1f75fe"]}
                >
                  What About the Deacon?
                </LinearGradient>
              </Typography>

              <Typography
                variant="h1"
                style={{
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "600",
                  color: "#f5f5f5",
                  lineHeight: 1.2,
                  marginBottom: "10px",
                }}
              >
                "What About the Deacon?" is a documentary by JustEldredge Media
                that examines the tragic case of Deacon Johnny Hollman, a
                63-year-old man killed by an Atlanta police officer and a tow
                truck driver after calling for help with a minor fender bender.
                More than a year later, neither has been indicted, casting a
                shadow over Atlanta's judicial system. The film highlights the
                resilient fight of Deacon Hollman’s children and their community
                as they continue to demand justice.
                <br />
              </Typography>
              <div style={{ paddingTop: "10px" }}>
                <Button
                  variant="contained"
                  href="https://www.youtube.com/watch?v=bdvvTyF7U48"
                  target="_blank"
                  style={{
                    background: "rgb(31, 117, 254)",
                    marginRight: "10px",
                    fontWeight: "700",
                    textTransform: "none",
                    marginBottom: isMobile ? "10px" : 0,
                    width: isMobile ? "100%" : "auto",
                  }}
                  startIcon={<PlayCircleOutline style={{ color: "#222" }} />}
                >
                  Watch the Documentary
                </Button>
                <Button
                  variant="contained"
                  href="https://campaigns.organizefor.org/petitions/justice-for-deacon-johnny-hollman-no-more-delays-charge-the-officer-and-tow-driver-now"
                  target="_blank"
                  style={{
                    background: "rgb(31, 117, 254)",
                    fontWeight: "700",
                    textTransform: "none",
                    marginBottom: isMobile ? "10px" : 0,
                    width: isMobile ? "100%" : "auto",
                  }}
                  startIcon={
                    <NoteAltOutlined
                      style={{ color: "#222", fontSize: "20px" }}
                    />
                  }
                >
                  Sign the Petition
                </Button>
              </div>
            </Grid>
            <Grid size={7}></Grid>
          </Grid>
        </div>

        {/* Hero Section */}
        <Box
          my={4}
          textAlign="center"
          position="relative"
          style={{
            background: "#000",
            paddingTop: isMobile ? 0 : "20px",
            marginTop: 0,
          }}
        >
          <div
            style={{
              // position: "absolute",
              // top: "-220px",
              width: "100%",
              // height: isMobile ? 0 : "220px",
            }}
          />

          {/* Community Reactions */}
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
            style={{
              position: "relative",
              // top: "-40px",
              color: "#fff",
              fontSize: isMobile ? "28px" : "40px",
              fontWeight: "900",
              fontFamily: "inherit",
              marginTop: isMobile ? "140px" : 0,
              marginBottom: "100px",
            }}
          >
            <LinearGradient
              gradient={["to bottom", "#1f75fe, skyblue, silver, #1f75fe"]}
            >
              Community Reactions
            </LinearGradient>
          </Typography>

          {/* <ReactionsGrid /> */}
          <Grid
            container
            spacing={4}
            style={{ display: "flex", padding: "0 20px 20px" }}
          >
            {reactionVideos.map((reaction) => (
              <Grid
                size={{ xs: 6, md: 3 }}
                position="relative"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height={isMobile ? "200px" : "360px"}
                    image={require(`../assets/reaction${reaction.img}.png`)}
                    alt="documentary reaction"
                  />
                </Card>
                <IconButton
                  style={{
                    position: "absolute",
                    color: "rgba(31, 117, 254,0.6)",
                  }}
                  onClick={() => setCurrentReactionVideo(reaction.video)}
                  aria-label="play documentary reaction"
                >
                  <PlayCircle
                    style={{ fontSize: isMobile ? "60px" : "140px" }}
                  />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* <Reactions /> */}
        {/* <VideoList storage={storage} /> */}

        {/* Petition Signature Counter */}
        <Box
          textAlign="center"
          my={4}
          style={{
            display: "flex",
            height: "50vh",
            backgroundColor: "#f5f5f5",
            background: `url(${signaturesBg})`,
            backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "100%",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            padding: "100px",
          }}
        >
          <div>
            <Typography
              variant="h2"
              fontWeight="900"
              style={{
                color: "rgb(31, 117, 254)",
                fontSize: isMobile ? "30px" : "60px",
                lineHeight: 1,
              }}
            >
              <LinearGradient
                gradient={["to bottom", "#1f75fe, skyblue, silver, #1f75fe"]}
              >
                {/* <CountUp
                  end={signatures}
                  start={0}
                  separator=","
                  duration={180}
                /> */}
                Petition Signature Goal:{" "}
                <span style={{ fontWeight: "200" }}>50,000</span>
              </LinearGradient>
            </Typography>
            <Typography
              style={{
                color: "#f5f5f5",
                fontWeight: "600",
                fontSize: isMobile ? "18px" : "28px",
              }}
              variant="h5"
              gutterBottom
            >
              Join us in making our voices heard.
            </Typography>
            <Button
              href="https://campaigns.organizefor.org/petitions/justice-for-deacon-johnny-hollman-no-more-delays-charge-the-officer-and-tow-driver-now"
              target="_blank"
              variant="contained"
              style={{
                background: "rgb(31, 117, 254)",
                marginTop: "20px",
                fontWeight: "700",
                textTransform: "none",
              }}
              // startIcon={<PlayCircleOutline style={{ color: "#222" }} />}
            >
              Add Your Name
            </Button>
          </div>
        </Box>

        {/* Watch the Trailer Section */}
        <Box
          style={{
            display: "flex",
            backgroundColor: "rgb(31, 117, 254)",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              size={{ xs: 12 }}
              style={{
                textAlign: "center",
                padding: isMobile ? 0 : "0 0 0 0px",
              }}
            >
              <div
                style={{
                  marginBottom: 0,
                }}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  style={{
                    color: "#f5f5f5",
                    fontWeight: "700",
                    fontSize: isMobile ? "20px" : "40px",
                    lineHeight: 1,
                    padding: "20px",
                    marginBottom: 0,
                  }}
                  gutterBottom
                >
                  <LinearGradient gradient={["to top", "aqua, #fff, silver"]}>
                    Watch the Trailer
                  </LinearGradient>
                </Typography>
              </div>
              <ReactPlayer
                url="https://youtu.be/AyGB02NzTRE"
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
                width="100vw"
                height="75vw"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Host a Screening Section */}
        <Box
          style={{
            display: "flex",
            backgroundColor: "rgb(31, 117, 254)",
            padding: isMobile ? "20px" : "100px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <div>
                <img
                  src={screening}
                  alt="Host a Screening"
                  style={{ width: "100%" }}
                />
              </div>
            </Grid>
            <Grid
              size={{ xs: 12, md: 5 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? 0 : "0 0 0 40px",
              }}
            >
              <div style={{ marginBottom: isMobile ? "40px" : 0 }}>
                <Typography
                  variant="h3"
                  component="h3"
                  style={{
                    color: "#f5f5f5",
                    fontWeight: "700",
                    lineHeight: 1,
                    marginBottom: "10px",
                  }}
                  gutterBottom
                >
                  Host a Screening
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: "#f5f5f5",
                    fontSize: isMobile ? "14px" : "18px",
                    fontWeight: "400",
                    fontFamily: "inherit",
                    lineHeight: 1.2,
                  }}
                  gutterBottom
                >
                  By hosting a screening of "What About the Deacon?", you help
                  raise awareness about Deacon Johnny Hollman's case and
                  strengthen our call for justice from the Fulton County DA's
                  office.
                  <br />
                  <br />
                  Whether it’s four friends at home or 100 at a community
                  center, your support matters. Join us in sparking discussions
                  by hosting a screening today!
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setHostingFormVisible(true)}
                  style={{
                    color: "rgb(31, 117, 254)",
                    background: "#f5f5f5",
                    marginTop: "20px",
                    fontWeight: "700",
                    textTransform: "none",
                  }}
                  startIcon={<Theaters style={{ color: "#222" }} />}
                >
                  Host Screening
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>

        {/* FOOTER */}
        <Box style={{ padding: "20px", background: "#111" }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
              <Typography
                variant="body2"
                style={{ color: "#f5f5f5", fontWeight: "700" }}
              >
                © {moment().format("YYYY")} What About the Deacon
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Modal
        open={hostingFormVisible}
        onClose={() => setHostingFormVisible(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ScreeningForm
          DB={DB}
          isMobile={isMobile}
          setHostingFormVisible={setHostingFormVisible}
        />
      </Modal>

      <Modal
        open={uploadFormVisible}
        onClose={() => setUploadFormVisible(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "400px",
            height: isMobile ? "100%" : "auto",
            margin: "auto",
            background: "#f5f5f5",
            padding: "20px",
          }}
        >
          <Typography variant="h4" style={{ fontSize: "20px" }}>
            Send Us Your Reaction
          </Typography>
          {/* Full Name Input */}
          <TextField
            label="Full Name"
            name="fullName"
            variant="outlined"
            fullWidth
            value={formData.fullName}
            onChange={handleInputChange}
          />
          {/* Email Input */}
          <TextField
            label="Email Address"
            name="email"
            type="email"
            variant="outlined"
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
          />
          {/* File Upload */}
          <Button
            variant="contained"
            disabled={uploading}
            component="label"
            style={{ backgroundColor: "#222" }}
            startIcon={<Upload />}
          >
            Upload Video
            <input
              type="file"
              accept="video/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {/* Show selected file name */}
          {formData.file && (
            <Typography variant="body1">
              Selected File: {formData.file.name}
            </Typography>
          )}
          {/* Show upload progress */}
          {uploading && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircularProgress size={24} />
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {`Uploading... ${Math.round(uploadProgress)}%`}
              </Typography>
            </Box>
          )}
          {/* Submit Button */}
          <Button
            disabled={uploading || !formData.file}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>

          <Button
            onClick={() => setUploadFormVisible(false)}
            variant="contained"
            style={{ backgroundColor: "#777" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={currentReactionVideo}
        onClose={() => setCurrentReactionVideo(null)}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            background: "rgba(0,0,0,0.4)",
            width: "100%",
            height: "100%",
          }}
        >
          {currentReactionVideo && (
            <ReactPlayer
              auto
              playing
              controls
              url={[{ src: currentReactionVideo, type: "video/mp4" }]}
              height={isMobile ? "80vh" : "100vh"}
            />
          )}
          <IconButton
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "#f5f5f5",
            }}
            onClick={() => setCurrentReactionVideo(null)}
            aria-label="play documentary reaction"
          >
            <Close style={{ fontSize: isMobile ? "40px" : "80px" }} />
          </IconButton>
        </Box>
      </Modal>

      <Button
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
          background: "#f5f5f5",
          color: "rgb(31, 117, 254)",
          fontSize: "14px",
        }}
        onClick={() => setUploadFormVisible(true)}
        variant="contained"
        startIcon={<Videocam style={{ fontSize: "30px", color: "#222" }} />}
      >
        Send Us Your Reaction
      </Button>
    </>
  );
};
