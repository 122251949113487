import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { collection, addDoc } from "firebase/firestore";

export const ScreeningForm = ({ DB, isMobile, setHostingFormVisible }) => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    publicScreening: "",
    location: "",
    address: "",
    goalAttendees: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Add form data to Firestore
      await addDoc(collection(DB, "screenings"), {
        ...formData,
        timestamp: new Date(),
      });

      alert("Form submitted successfully!");

      // Reset the form
      setFormData({
        name: "",
        number: "",
        email: "",
        publicScreening: "",
        location: "",
        address: "",
        goalAttendees: "",
      });
    } catch (error) {
      console.error("Error submitting form to Firestore: ", error);
      alert("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }

    setHostingFormVisible(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "400px",
        height: isMobile ? "100%" : "auto",
        margin: "auto",
        background: "#f5f5f5",
        padding: "20px",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Screening Form
      </Typography>

      {/* Name Input */}
      <TextField
        label="Name"
        name="name"
        variant="outlined"
        fullWidth
        value={formData.name}
        onChange={handleInputChange}
      />

      {/* Number Input */}
      <TextField
        label="Phone Number"
        name="number"
        variant="outlined"
        fullWidth
        value={formData.number}
        onChange={handleInputChange}
      />

      {/* Email Input */}
      <TextField
        label="Email"
        name="email"
        type="email"
        variant="outlined"
        fullWidth
        value={formData.email}
        onChange={handleInputChange}
      />

      {/* Is your screening open to the public? */}
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Is your screening open to the public?
        </FormLabel>
        <RadioGroup
          name="publicScreening"
          value={formData.publicScreening}
          onChange={handleInputChange}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      {/* Location and Address */}
      {formData.publicScreening === "yes" && (
        <>
          <TextField
            label="Location"
            name="location"
            variant="outlined"
            fullWidth
            value={formData.location}
            onChange={handleInputChange}
          />
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            fullWidth
            value={formData.address}
            onChange={handleInputChange}
          />
        </>
      )}

      {/* Goal for attendees */}
      <TextField
        select
        label="Goal for attendees at your screening"
        name="goalAttendees"
        fullWidth
        value={formData.goalAttendees}
        onChange={handleInputChange}
      >
        <MenuItem value="1-5">1-5</MenuItem>
        <MenuItem value="5-10">5-10</MenuItem>
        <MenuItem value="10-15">10-15</MenuItem>
        <MenuItem value="15-20">15-20</MenuItem>
        <MenuItem value="25+">25+</MenuItem>
      </TextField>

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {loading ? "Submitting..." : "Submit"}
      </Button>

      <Button
        variant="contained"
        disabled={loading}
        onClick={() => setHostingFormVisible(false)}
        style={{ backgroundColor: "#222", color: "#f5f5f5" }}
      >
        Cancel
      </Button>
    </Box>
  );
};
